@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';

.block-section4-section-wrap {
  padding: 64px 0 24px;
  .certifikate-item {
    margin: 24px 0;
    .image-wrap {
      width: 100%;
      height: 190px;
      background-color: $green-50;
      border-radius: 10px;
      position: relative;
      margin-bottom: 48px;
      .img-item {
        position: absolute;
        width: 220px;
        height: 260px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        //overflow: hidden;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.15));
      }
    }
    .title {
      color: $secondary;
      font-size: toRem(18);
      margin-bottom: 11px;
      font-weight: 500;
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 96px 0 24px;
    h2 {
      margin-bottom: 48px;
    }
    .certifikate-item {
      margin: 36px 0;
      .image-wrap {
        height: 220px;
        margin-bottom: 96px;
        .img-item {
          width: 260px;
          height: 310px;
        }
      }
    }
  }
}